import React from 'react';
import cn from 'classnames';
import { useSeoBlocksHidden, useShowingSeoBlocks } from '../../../store/reducers/metadata';
import { SeoBlocks } from './seo.blocks';
import { Breadcrumbs } from './breadcrumbs';
import { PreFooter } from './prefooter';
import styles from './styles.module.scss';

export const AllSeoBlocks = () => {
    const { seoBlocks, prefooter, breadcrumbs } = useShowingSeoBlocks();
    const isHidden = useSeoBlocksHidden();

    if ((!seoBlocks && !prefooter && !breadcrumbs) || isHidden) {
        return null;
    }

    return (
        <div className={cn(styles.seo_blocks_wrapper)}>
            {seoBlocks && <SeoBlocks className={cn(styles.seo_block_width_container)} />}
            {breadcrumbs && <Breadcrumbs className={cn(styles.seo_block_width_container)} />}
            {prefooter && <PreFooter />}
        </div>
    );
};
