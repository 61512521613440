import { createAction, createReducer } from 'redux-act';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../index';

type Statuses = {
    [key: string]: FB.CleanStatus;
};

export type BankStatuses = {
    [key: string]: string;
};

export type BankStatusesTs = {
    [key: string]: number | undefined;
};

export interface IState {
    statuses: Statuses;
    delayedStatuses: Statuses;
    bankStatuses: BankStatuses;
    bankStatusesTs: BankStatusesTs;
    userId?: string;
    loaded: boolean;
}

const defaultState: IState = {
    statuses: {},
    delayedStatuses: {},
    bankStatuses: {},
    bankStatusesTs: {},
    loaded: false,
};

export const setStatusesHandler = (
    userId: string | undefined,
    statuses: Statuses,
    delayedStatuses = {} as Statuses,
): IState => {
    const bankStatuses: BankStatuses = {};
    const bankStatusesTs: BankStatusesTs = {};
    for (const { bankId, status, ts } of Object.values(statuses)) {
        bankStatuses[bankId] = status;
        bankStatusesTs[bankId] = ts;
    }
    return { userId, statuses, delayedStatuses, bankStatuses, bankStatusesTs, loaded: true };
};

export const setStatuses =
    createAction<{ userId?: string; statuses: Statuses; delayedStatuses?: Statuses }>('set statuses');

export const setStatus = createAction<{ productId: string | number; status: FB.CleanStatus }>('set status');

export const useFbStatuses = () => useSelector((state: IGlobalState) => state.fbStatuses as IState);

export const fbStatusesReducer = createReducer<IState>({}, defaultState)
    .on(setStatuses, (state, { userId, statuses, delayedStatuses }) => {
        return setStatusesHandler(userId, statuses, delayedStatuses);
    })
    .on(setStatus, (state, { productId, status }) => {
        if (status && isEqual(state.statuses[productId], status)) {
            return state;
        }
        const statuses = { ...state.statuses, [productId]: status };
        const bankStatuses: BankStatuses = {};
        const bankStatusesTs: BankStatusesTs = {};
        for (const { bankId, status: s, ts } of Object.values(statuses)) {
            bankStatuses[bankId] = s;
            bankStatusesTs[bankId] = ts;
        }
        return { ...state, statuses, bankStatuses };
    });
