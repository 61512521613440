import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { IPublicUser } from '@sravni/types/lib/auth';
import { IGlobalState } from '../index';
import { ClientTraceContext } from '../../helpers/clientTraceContext';

type ILoggedInState = {
    loggedIn: true;
    loggedInSilent: boolean;
    account: IPublicUser;
};

type ILoggedOutState = {
    loggedIn: false;
    loggedInSilent: false;
};

type IState = ILoggedInState | ILoggedOutState;

const initialState: IState = {
    loggedIn: false,
    loggedInSilent: false,
};

export const authorizeUser = createAction<IPublicUser>('authorize user');

export const silentAuthorizeUser = createAction<Partial<IPublicUser>>('silent authorize user');

export const destroyUser = createAction('destroy user');

/** Old AB-testing mechanism removed in CF-1684 */

export const userReducer = createReducer<IState>({}, initialState)
    .on(authorizeUser, (state, payload) => {
        ClientTraceContext.setUserId(payload.sub);
        return {
            ...state,
            loggedIn: true,
            loggedInSilent: false,
            account: payload,
        };
    })
    .on(silentAuthorizeUser, (state, payload) => ({
        ...state,
        ...(payload.sub
            ? {
                  loggedIn: true,
                  loggedInSilent: true,
                  account: payload,
              }
            : ({} as any)),
    }))
    .on(destroyUser, () => {
        ClientTraceContext.setUserId(null);
        return initialState;
    });

const userSelector = (state: IGlobalState) => (state.user.loggedIn ? state.user.account : null);

const loggedInSelector = (state: IGlobalState) => state.user.loggedIn;
const loggedInSilentSelector = (state: IGlobalState) => state.user.loggedInSilent;

export function useUser() {
    return useSelector(userSelector);
}

export function useLoggedIn() {
    return useSelector(loggedInSelector);
}

export function useLoggedInSilent() {
    return useSelector(loggedInSilentSelector);
}
