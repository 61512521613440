import * as Sentry from '@sentry/browser';
import { randomString } from './random';

/** Browser only */
export class ClientTraceContext {
    /** Следует менять только при перезагрузке страницы */
    private static sessionId: string = '';
    /** Sravni Id авторизованного пользователя */
    private static userId: string = '';
    /** Sravni Id владельца анкеты */
    private static ownerId: string = '';
    /** Название текущей страницы / раздела */
    private static pageContext: string = '';

    /** Создает или задает sessionId */
    public static setSessionId(sessionId?: string): string {
        this.sessionId = sessionId || randomString();
        Sentry.setTag('sessionId', this.sessionId);
        return this.sessionId;
    }

    /** Создает новый traceId для запроса */
    public static getTraceId(): string {
        const traceId = randomString();
        Sentry.setTag('traceId', traceId);
        return traceId;
    }

    /** Устанавливает Sravni Id авторизованного пользователя */
    public static setUserId(userId?: string | null): string {
        this.userId = userId || '';
        Sentry.setUser(userId ? { id: userId } : null);
        return this.userId;
    }

    /** Устанавливает Sravni Id владельца анкеты */
    public static setOwnerId(ownerId?: string | null): string {
        this.ownerId = ownerId || '';
        Sentry.setTag('ownerId', ownerId || '');
        return this.ownerId;
    }

    public static setPageContext(pageContext: string): string {
        this.pageContext = pageContext;
        Sentry.setTag('pageContext', pageContext);
        return this.pageContext;
    }

    /** Возвращает список трассировочных данных */
    public static getTraceData() {
        return {
            sessionId: this.sessionId,
            userId: this.userId,
            ownerId: this.ownerId,
            pageContext: this.pageContext,
        };
    }
}
